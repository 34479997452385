"use strict";

Object.defineProperty(exports, "__esModule", {
  value: !0
}), exports.StringBuilder = exports.String = exports.formatString = exports.joinString = exports.isNullOrWhiteSpace = exports.emptyString = void 0;
const EOL = "\r\n";
function isNullOrWhiteSpace(value) {
  return String.isNullOrWhiteSpace(value);
}
function joinString(delimiter, ...args) {
  return String.join(delimiter, ...args);
}
function formatString(format, ...args) {
  return String.format(format, ...args);
}
exports.emptyString = "", exports.isNullOrWhiteSpace = isNullOrWhiteSpace, exports.joinString = joinString, exports.formatString = formatString;
class String {
  static regexNumber = /{(\d+(:\w*)?)}/g;
  static regexObject = /{(\w+(:\w*)?)}/g;
  static empty = "";
  static Empty = "";
  static IsNullOrWhiteSpace(value) {
    return String.isNullOrWhiteSpace(value);
  }
  static Join(delimiter, ...args) {
    return String.join(delimiter, ...args);
  }
  static Format(format, ...args) {
    return String.format(format, ...args);
  }
  static isNullOrWhiteSpace(value) {
    try {
      return null == value || "undefined" == value ? !0 : value.toString().replace(/\s/g, "").length < 1;
    } catch (e) {
      return console.log(e), !1;
    }
  }
  static join(delimiter, ...args) {
    try {
      var firstArg = args[0];
      if (Array.isArray(firstArg) || firstArg instanceof Array) {
        let tempString = String.empty;
        for (let i = 0; i < firstArg.length; i++) {
          var current = firstArg[i];
          i < firstArg.length - 1 ? tempString += current + delimiter : tempString += current;
        }
        return tempString;
      }
      if ("object" == typeof firstArg) {
        let tempString = String.empty;
        const objectArg = firstArg;
        return Object.keys(firstArg).forEach(element => {
          tempString += objectArg[element] + delimiter;
        }), tempString = tempString.slice(0, tempString.length - delimiter.length);
      }
      var stringArray = args;
      return String.joinString(delimiter, ...stringArray);
    } catch (e) {
      return console.log(e), String.empty;
    }
  }
  static format(format, ...args) {
    try {
      return format.match(String.regexNumber) ? String.formatString(String.regexNumber, format, args) : format.match(String.regexObject) ? String.formatString(String.regexObject, format, args, !0) : format;
    } catch (e) {
      return console.log(e), String.empty;
    }
  }
  static formatString(regex, format, args, parseByObject = !1) {
    return format.replace(regex, function (match, x) {
      var s = match.split(":");
      1 < s.length && (x = s[0].replace("{", ""), match = s[1].replace("}", ""));
      let arg;
      return null == (arg = (parseByObject ? args[0] : args)[x]) || null == arg || match.match(/{\d+}/) || void 0 !== (arg = String.parsePattern(match, arg)) && null != arg ? arg : String.empty;
    });
  }
  static parsePattern(match, arg) {
    switch (match) {
      case "L":
        return arg = arg.toLocaleLowerCase();
      case "U":
        return arg = arg.toLocaleUpperCase();
      case "d":
        if ("string" == typeof arg) return String.getDisplayDateFromString(arg);
        if (arg instanceof Date) return String.format("{0:00}.{1:00}.{2:0000}", arg.getDate(), arg.getMonth(), arg.getFullYear());
        break;
      case "s":
        if ("string" == typeof arg) return String.getSortableDateFromString(arg);
        if (arg instanceof Date) return String.format("{0:0000}-{1:00}-{2:00}", arg.getFullYear(), arg.getMonth(), arg.getDate());
        break;
      case "n":
        {
          var replacedString = (arg = "string" != typeof arg ? arg.toString() : arg).replace(/,/g, ".");
          if (isNaN(parseFloat(replacedString)) || replacedString.length <= 3) break;
          replacedString = replacedString.split(/\D+/g);
          let parts = replacedString;
          var replacedString = (parts = 1 < replacedString.length ? [String.joinString("", ...replacedString.splice(0, replacedString.length - 1)), replacedString[replacedString.length - 1]] : parts)[0],
            mod = replacedString.length % 3,
            output = 0 < mod ? replacedString.substring(0, mod) : String.empty,
            replacedString = replacedString.substring(mod).match(/.{3}/g);
          return arg = output + "." + String.join(".", replacedString) + (1 < parts.length ? "," + parts[1] : "");
        }
      case "x":
        return this.decimalToHexString(arg);
      case "X":
        return this.decimalToHexString(arg, !0);
    }
    return "number" != typeof arg && isNaN(arg) || isNaN(+match) || String.isNullOrWhiteSpace(arg) ? arg : String.formatNumber(arg, match);
  }
  static decimalToHexString(value, upperCase = !1) {
    value = parseFloat(value).toString(16);
    return upperCase ? value.toLocaleUpperCase() : value;
  }
  static getDisplayDateFromString(input) {
    var splitted = input.split("-");
    if (splitted.length <= 1) return input;
    let day = splitted[splitted.length - 1];
    input = splitted[splitted.length - 2], splitted = splitted[splitted.length - 3];
    return (day = (day = day.split("T")[0]).split(" ")[0]) + `.${input}.` + splitted;
  }
  static getSortableDateFromString(input) {
    var splitted = input.replace(",", "").split(".");
    if (splitted.length <= 1) return input;
    input = splitted[splitted.length - 1].split(" ");
    let time = String.empty,
      result = (1 < input.length && (time = input[input.length - 1]), splitted[splitted.length - 1].split(" ")[0] + `-${splitted[splitted.length - 2]}-` + splitted[splitted.length - 3]);
    return !String.isNullOrWhiteSpace(time) && 1 < time.length ? result += "T" + time : result += "T00:00:00", result;
  }
  static formatNumber(input, formatTemplate) {
    var formatTemplate = formatTemplate.length,
      input = input.toString();
    return formatTemplate <= input.length ? input : (formatTemplate = formatTemplate - input.length, ++formatTemplate, new Array(formatTemplate).join("0") + input);
  }
  static joinString(delimiter, ...args) {
    let temp = String.empty;
    for (let i = 0; i < args.length; i++) if (!("string" == typeof args[i] && String.isNullOrWhiteSpace(args[i]) || "number" != typeof args[i] && "string" != typeof args[i])) {
      var arg = "" + args[i];
      temp += arg;
      for (let i2 = i + 1; i2 < args.length; i2++) if (!String.isNullOrWhiteSpace(args[i2])) {
        temp += delimiter, i = i2 - 1;
        break;
      }
    }
    return temp;
  }
}
exports.String = String;
class StringBuilder {
  Values;
  constructor(value = "") {
    this.Values = [], String.isNullOrWhiteSpace(value) || (this.Values = new Array(value));
  }
  toString() {
    return this.Values.join(String.empty);
  }
  ToString() {
    return this.toString();
  }
  append(value) {
    this.Values.push(value);
  }
  Append(value) {
    this.append(value);
  }
  appendLine(value) {
    this.Values.push(EOL + value);
  }
  AppendLine(value) {
    this.appendLine(value);
  }
  appendFormat(format, ...args) {
    this.Values.push(String.format(format, ...args));
  }
  AppendFormat(format, ...args) {
    this.appendFormat(format, ...args);
  }
  appendLineFormat(format, ...args) {
    this.Values.push(EOL + String.format(format, ...args));
  }
  AppendLineFormat(format, ...args) {
    return this.appendLineFormat(format, ...args);
  }
  clear() {
    this.Values = [];
  }
  Clear() {
    this.clear();
  }
}
exports.StringBuilder = StringBuilder;